@import '../variables';

.one-click-container {
  display: inline-block;
  text-align: center;
  position: relative;

  @media screen and (max-width: $mobile) {
    margin: 50px 0;
  }

  .icon-container {
    position: relative;
    width: 120px;
    height: 140px;
  }

  .app-icon {
    position: absolute;
    z-index: 2;
    height: 120px;
    width: 120px;
    top: 0;
    left: 0;
  }

  .app-label {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .app-icon-color {
    position: absolute;
    z-index: 2;
    height: 120px;
    width: 120px;
    opacity: 0;
    top: 0;
    left: 0;
  }

  .cursor {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
  }

  .icon-shadow {
    display: none;
    opacity: 0;
    position: absolute;
    top: 7px;
    background: #bbb;
    height: 120px;
    width: 120px;
    z-index: 1;
    border-radius: 16px;
  }

  .confetti-root {
    position: absolute;
    left: 50px;
    width: 1px;
    height: 1px;
  }
}
