.help-tip {
  display: inline-block;
  position: relative;
  margin-left: 7px;
  vertical-align: text-bottom;
  cursor: help;

  &:hover {
    .help-icon {
      opacity: 0.8;
      color: white;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(0, 0, 0);
      border-image: initial;
      background: black;
    }
  }

  .help-icon {
    box-sizing: border-box;
    color: rgb(68, 68, 68);
    opacity: 0.7;
    background-color: #fff;
    font-size: 13px;
    width: 20px;
    display: inline-block;
    text-align: center;
    height: 20px;
    vertical-align: middle;
    line-height: 18px;
    font-weight: bold;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    transition: all 0.5s ease 0s;
  }

  .help-tooltip-wrapper {
    display: none;
    opacity: 0;
    position: absolute;
    z-index: 1024;
    top: 30px;
    left: calc(-10rem + 11px);

    @media screen and (min-width: 640px) {
      top: 33px;
    }
  }

  .help-tooltip-inner {
    width: 24rem;
    background-color: rgba(0, 0, 0, 0.75);
    font-weight: 400;
    color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 6px 8px;
    padding: 8px 14px;

    a {
      color: #fff !important;
      text-decoration: underline !important;
    }

    @media screen and (max-width: 639px) {
      width: 18rem;
      left: auto;
    }
  }

  &:hover {
    .help-tooltip-arrow {
      display: block;
      opacity: 1;

      @media screen and (max-width: 639px) {
        visibility: hidden;
      }
    }

    .help-tooltip-wrapper {
      display: block;
      opacity: 1;

      @media screen and (max-width: 639px) {
        visibility: hidden;
      }
    }
  }
}
