@import "../../node_modules/bourbon-neat/core/neat";
@import "../../node_modules/bourbon/core/bourbon";
@import "media";

$primary-color: #006df6;
$light-blue: hsl(203, 100%, 90%);
$mobile: 860px;

$mobile-grid: (columns: 1,
  gutter: 20px,
  media: "screen and (max-width: 860px)",
  direction: ltr);

$reverse-grid: (direction: rtl);