@import "../variables";

.convert-your-app {
  .logo {
    display: block;
    margin: 20px auto;
  }

  .title {
    font-weight: normal;
    margin: 80px auto;
    margin-bottom: 60px;
    line-height: 50px;
    color: #363636;
    font-size: 2rem;
    text-align: center;

    &.extra-space {
      margin-bottom: 160px;
    }
  }

  svg.arrow {
    @media screen and (max-width: $mobile) {
      display: none;
    }

    width: 110px;
    height: 110px;
    transform: rotate(-68deg) translate(-14px, -22px);
    position: absolute;

    .draw-arrow {
      stroke-width: 5;
      stroke: $primary-color;
      fill: none;
      stroke-dasharray: 400;
      stroke-dashoffset: 400;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-name: draw;

      &.tail-1 {
        animation-delay: 0.5s;
      }

      &.tail-2 {
        animation-delay: 0.7s;
      }
    }
  }

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes draw-line {
    to {
      width: 100%;
    }
  }

  .line-container {
    position: relative;
    white-space: nowrap;

    > .line-inner {
      position: absolute;
      width: 0%;
      bottom: -6px;
      transform: rotate(-0.8deg);
      height: 7px;
      background: $primary-color;
      border-radius: 6px;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-name: draw-line;
      animation-delay: 1.2s;
    }
  }

  @keyframes pulse-button {
    0% {
      transform: scale(0.5);
      opacity: 0.25;
    }

    20% {
      transform: scale(1.3);
      opacity: 0;
    }

    40% {
      transform: scale(1);
      opacity: 0;
    }
  }
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

form.enter-url,
form.notify-signup {
  max-width: 700px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 150px;

  label {
    color: #363636;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5em;

    display: block;
  }

  .help {
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }

  .error {
    display: none;
    background: hsl(0, 70%, 90%);
    color: hsl(0, 100%, 22%);
    padding: 10px;
    border-radius: 4px;
    border: 1px hsl(0, 70%, 80%) solid;

    &.has-error {
      display: block;
    }
  }

  .negative-response {
    display: none;
    background: hsl(0, 100%, 97%);
    padding: 5px 10px;
    border: 1px solid hsl(0, 80%, 78%);
    border-radius: 5px;
  }

  .positive-response {
    display: none;
    background: hsl(213, 100%, 96%);
    padding: 5px 10px;
    border: 1px solid hsl(213, 80%, 86%);
    border-radius: 5px;
  }
}

.input-container {
  ///////////////////////////////////////////////////////////////////////////////////
  $base-spacing: 1.5em !default;
  $base-border-color: gainsboro !default;
  $base-line-height: 1.5em !default;
  //////////////////////////////////////////////////////////////////////////////////

  $search-bar-border-color: $base-border-color;
  $search-bar-border: 1px solid $search-bar-border-color;
  $search-bar-background: lighten($search-bar-border-color, 10%);

  position: relative;
  margin-bottom: 0.75rem;

  img {
    display: none;

    @include mobile-landscape-and-up {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      position: absolute;
      z-index: 1;
      top: 0.95rem;
      left: 0.7rem;
      opacity: 0.3;
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }

  input {
    appearance: none;
    background-color: white;
    border: 1px solid $base-border-color;
    box-sizing: border-box;
    display: block;
    // font-size: 1em;
    // font-style: italic;
    margin: 0;
    padding: ($base-spacing / 3) ($base-spacing / 3);

    @include mobile-landscape-and-up {
      padding-left: 3rem;
    }

    position: relative;
    transition: border-color;
    width: 100%;
    font-size: 1.2rem;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 0.25em rgba(50, 115, 220, 0.25);
    border-color: rgba(50, 115, 220, 0.25);

    &:focus {
      border-color: #3273dc;
    }

    &.has-error {
      border-color: hsl(0, 71%, 53%);
      box-shadow: 0 0 0 0.25em hsla(0, 71%, 53%, 0.25);
    }

    &::placeholder {
      color: #bbb;
    }

    @include mobile-landscape-and-up {
      font-size: 1.5rem;
    }
  }

  button[type="submit"] {
    // @include position(absolute, 0 0 0 null);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    outline: none;
    padding: 5px 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $primary-color;
    border-color: transparent;
    color: #fff;
    font-size: 1.5rem;
    padding-left: 0.75em;
    padding-right: 0.75em;
    cursor: pointer;

    &:hover {
      background: #0067e9;
    }

    &:disabled {
      background: hsl(213, 100%, 68%);
    }

    &.is-loading {
      .pulser {
        display: none;
      }
    }
  }

  .text {
    position: relative;
    z-index: 2;

    &.long {
      display: none;

      @include mobile-landscape-and-up {
        display: block;
      }
    }

    &.short {
      font-size: 1rem;
      display: block;

      @include mobile-landscape-and-up {
        display: none;
      }
    }
  }

  .pulser {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    // @include position(absolute, 0 0 0 null);
    outline: 15px $primary-color solid;
    height: 100%;
    width: 100%;
    z-index: 1;
    border-radius: 4px;
    animation: pulse-button 4s ease-out;
    animation-iteration-count: infinite;
    transform: scale(0);
    opacity: 0;
  }

  &.inverse {
    button {
      background-color: hsl(213, 100%, 18%);
      color: #fff;

      // border-left: 1px #006df6 solid;
      &:hover {
        background-color: hsl(213, 100%, 13%);
      }
    }

    .pulser {
      outline-color: hsl(213, 100%, 18%);
    }

    input:focus {
      border-color: hsl(213, 100%, 18%);
      box-shadow: 0 0 0 0.25em hsla(213, 100%, 18%, 0.25);
    }
  }
}

button[type="submit"] {
  position: relative;

  &.is-loading {
    color: transparent !important;
    pointer-events: none;

    &::after {
      -webkit-animation: spinAround 0.5s infinite linear;
      animation: spinAround 0.5s infinite linear;
      border: 4px solid white;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1.2rem;
      position: relative;
      width: 1.2rem;

      position: absolute;
      left: calc(50% - ((1.3rem + 4px) / 2));
      top: calc(50% - ((1.4rem + 4px) / 2));
      position: absolute !important;
    }
  }
}

.quick-feature-overview {
  @include grid-container;
  margin-bottom: 180px;
  margin-top: -50px;
  background: #fff;
  border-radius: 5px;
  color: #4c6690;
  padding: 15px 0;
  @media screen and (max-width: $mobile) {
    padding: 0 15px;
    margin-bottom: 120px;
    margin-top: -80px;
  }
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 14px 2px;
  box-shadow: rgba(50, 115, 220, 0.15) 0px 0px 14px 2px;

  .title {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  ul {
    // @include grid-collapse;
    padding: 0;
    margin: 0;
    list-style-position: inside;
    list-style: none;

    li {
      @include grid-column(1 of 3);
      width: calc(33.33333% - 21px);
      margin-left: 0;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
      text-align: center;
      border-right: 1px rgba(50, 115, 220, 0.15) solid;

      @media screen and (max-width: $mobile) {
        // @include grid-column(1 of 1);
        width: calc(100% - 20px);
        border: none;
        border-bottom: 1px rgba(50, 115, 220, 0.15) solid;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      &:last-child {
        border: none;
      }
    }
  }

  h4 {
    text-align: center;
    color: #111;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
}

.feature-icon-box {
  height: 41px;
  width: 41px;
  background: #e6f1fe;
  border-radius: 100%;
  overflow: hidden;
  line-height: 51px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 8px;
  user-select: none;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  .apple-icon {
    position: absolute;
    left: 8px;
    top: 7px;
    transform: translateX(-40px);
    animation: os-icon-animation 8s infinite;
  }

  .windows-icon {
    position: absolute;
    left: 7px;
    top: 8px;
    transform: translateX(-40px);
    animation: os-icon-animation 8s 4s infinite;
  }

  .browser-container {
    position: absolute;
    top: 10px;
    left: 0;
    transform: translateX(50px);
    animation: browser-icon-animation 8s infinite;
    transform-origin: 17px 0;
  }

  .cursor-icon {
    position: absolute;
    left: 11px;
    top: 9px;
    transform: translate(40px, 40px);
    animation: cursor-icon-animation 5s infinite;
  }
}

@keyframes os-icon-animation {
  0% {
    transform: translateX(-40px);
  }
  10% {
    transform: translateX(0px);
  }
  40% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(40px);
  }
  100% {
    transform: translateX(40px);
  }
}

@keyframes cursor-icon-animation {
  0% {
    transform: translate(40px, 40px);
  }
  20% {
    transform: translate(0px, 0px);
  }
  47% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-2px);
  }
  53% {
    transform: translateY(0px);
  }
  80% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(-40px, -40px);
  }
}

@keyframes cursor-box-icon-animation {
  47% {
    transform: translateY(0px);
    background: rgba(104, 144, 241, 0.1);
  }
  50% {
    transform: translateY(2px);
    background: rgba(104, 144, 241, 0.2);
  }
  53% {
    transform: translateY(0px);
    background: rgba(104, 144, 241, 0.1);
  }
}

@keyframes browser-icon-animation {
  0% {
    transform: translateX(50px) scale(1);
  }
  8% {
    transform: translateX(0px) scale(1);
  }
  25% {
    transform: translateX(0px) scale(1);
  }
  52% {
    transform: translateX(0px) scale(0.5);
  }
  92% {
    transform: translateX(0px) scale(0.5);
  }
  100% {
    transform: translateX(-50px) scale(0.5);
  }
}

.browser-title-bar {
  animation: browser-title-bar-icon-animation 8s infinite;
}
.browser-address-bar {
  animation: browser-address-bar-icon-animation 8s infinite;
}
.browser-tab-bar {
  animation: browser-tab-bar-icon-animation 8s infinite;
}

@keyframes browser-title-bar-icon-animation {
  0% {
    transform: translateX(0px);
  }
  13% {
    transform: translateX(0px);
  }
  17% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(-50px);
  }
}
@keyframes browser-tab-bar-icon-animation {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(0px);
  }
  24% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(50px);
  }
}

@keyframes browser-address-bar-icon-animation {
  0% {
    transform: translateX(0px);
  }
  27% {
    transform: translateX(0px);
  }
  31% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(-50px);
  }
}
