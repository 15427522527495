@import '../variables';

.developer-time {
  background: rgba(255, 255, 255, 0.9);
  background: rgba(0, 0, 0, 0.7);
  background: hsl(217, 99%, 26%);
  background: hsl(213, 100%, 48%);
  background: white;
  color: white;
  color: #111;
  box-shadow: 0 4px 40px 0 rgba(77, 94, 126, 0.1);

  z-index: 100;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 40px;
  position: relative;
  border-radius: 0.25rem;
  text-align: center;
  color: hsl(213, 100%, 18%);

  p {
    font-size: 16px;
    margin-top: 0;
  }

  h2 {
    font-weight: normal;
    text-align: center;
    font-size: 28px;
    margin-top: 0;
    color: hsl(213, 100%, 18%);
  }
}

.cost-calculator {
  text-align: left;
  font-size: 1.1rem;

  @media screen and (max-width: $mobile) {
    font-size: 0.9rem;
  }

  .cost-row {
    @include grid-container;
    padding: 8px 0;
    margin: 1px 0;
    background: rgba(0, 0, 0, 0.15);
    background: hsla(213, 100%, 48%, 0.1);
    border-radius: 1px;
  }

  .cost-title {
    @include grid-column(5);

    @media screen and (max-width: $mobile) {
      @include grid-column(12);
    }
  }

  .cost-slider {
    @include grid-column(5);

    @media screen and (max-width: $mobile) {
      @include grid-column(8);
    }

    input {
      width: 100%;
    }
  }

  .cost-number {
    @include grid-column(2);

    @media screen and (max-width: $mobile) {
      @include grid-column(4);
    }

    text-align: right;
  }

  #cost-code-sign {
    .cost-title {
      @include grid-column(10);

      @media screen and (max-width: $mobile) {
        @include grid-column(8);
      }
    }
  }

  #cost-with-todesktop {
    .cost-title {
      @include grid-column(8);

      @media screen and (max-width: $mobile) {
        @include grid-column(8);
      }
    }

    .cost-number {
      @include grid-column(4);
    }
  }

  .cost-total {
    border-top: 1px white solid;
    font-weight: bold;
    background: white;
    background: hsl(213, 100%, 48%);
    background: #00408f;
    color: white;
    color: hsl(213, 100%, 95%);
    line-height: 1.5rem;
    padding: 1rem 0;
    font-size: 1.5rem;
    transform: scale(1.01);

    .cost-title {
      @include grid-column(8);
    }

    .cost-number {
      @include grid-column(4);
    }

    @media screen and (max-width: $mobile) {
      .cost-title {
        @include grid-column(8);
      }

      .cost-number {
        @include grid-column(4);
      }
    }

    @media screen and (max-width: $mobile) {
      font-size: 1.1rem;
    }
  }
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  margin: 7.3px 0;
  background: transparent;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 11.4px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: rgba(0, 109, 246, 0.78);
  background: hsl(217, 99%, 46%);
  border-radius: 5px;
  border: 0px solid #006df6;
  background: #00408f;
}

input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000031, 0px 0px 0px #00004b;
  border: 3px solid #006df6;
  // border-color: #00295c;
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7.3px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: rgba(17, 122, 255, 0.78);
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 11.4px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: rgba(0, 109, 246, 0.78);
  border-radius: 1.3px;
  border: 0px solid #006df6;
}

input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000031, 0px 0px 0px #00004b;
  border: 3px solid #006df6;
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 11.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: rgba(0, 98, 221, 0.78);
  border: 0px solid #006df6;
  border-radius: 2.6px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type='range']::-ms-fill-upper {
  background: rgba(0, 109, 246, 0.78);
  border: 0px solid #006df6;
  border-radius: 2.6px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type='range']::-ms-thumb {
  box-shadow: 0px 0px 0px #000031, 0px 0px 0px #00004b;
  border: 3px solid #006df6;
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
  height: 11.4px;
}

input[type='range']:focus::-ms-fill-lower {
  background: rgba(0, 109, 246, 0.78);
}

input[type='range']:focus::-ms-fill-upper {
  background: rgba(17, 122, 255, 0.78);
}
