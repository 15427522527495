@import "../variables";

.testimonial-grid {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;

  li:first-of-type {
    margin-right: 1.2rem;
  }

  li {
    width: 50%;
    background-repeat: no-repeat;

    b {
      display: block;
      margin-bottom: 0.8rem;
    }

    p {
      margin: 0 0 0 40px;
    }

    @media screen and (max-width: $mobile) {
      background-image: none !important;

      p {
        margin-left: 0;
      }
    }
  }

  &::after {
    display: block;
    content: "";
    clear: both;
  }

  .meta {
    font-size: 0.7rem;
    margin-left: 40px;
    margin-top: 15px;
    margin-bottom: 15px;

    @include grid-media($mobile-grid) {
      margin-left: 0;
    }

    .author {
      line-height: 1.2em;

      @include grid-media($mobile-grid) {
        text-align: center;
      }
    }

    .avatar {
      float: left;
      margin-top: 2px;
      height: 30px;
      width: 53px;

      @include grid-media($mobile-grid) {
        float: none;
        margin: 0 auto;
        margin-bottom: 8px;
      }

      background-size: 100%;
      background-repeat: no-repeat;
      margin-right: 12px;
    }

    .company a {
      color: inherit;
      text-decoration: underline;
      font-size: inherit;
    }
  }
}